import {React} from 'react'
import './Main.css';
import Homepage from '../pages/Homepage';
import {Link} from 'react-router-dom';
import img_background from '../assets/img/img_sfondo_2.png';

const Main = () => {

    //const img_background = 'https://www.rackspace.com/sites/default/files/styles/rxt_image/public/2020-11/big-data.jpeg?itok=E4yhDeVS';

    return (
        <>
            <div className='position-relative'>
                <img src={img_background} className="img-fluid custom_img" alt='img'/>
                <div className="position-absolute top-50 start-50 translate-middle text-center" style={{ zIndex: '1' }} >
                    <h2 className='text-white fw-bold text-outline'>Unica API Italia: Comuni, Province, Regioni.</h2>
                    <Link to='/doc' className='btn btn-xl btn_evidence text-white fs-4'>Documentazione</Link>
                </div>
            </div>
            <div className='mt-3' style={{ fontSize: '16px' }}>
                <div className="container mt-3">
                    <div className="row">
                        <div className='col-sm-2'></div>
                        <div className="col-sm-8 text-start mt-2">
                            <Homepage />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main